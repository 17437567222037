import { Typography } from "@mui/material";
import Link from "next/link";
import AnnouncementView from "@/features/App/views/announcement.view";
import { selectAuthInfo } from "@/features/Auth/slices/auth.slice";
import { useAppSelector } from "@/hooks/useAppSelector";

type Props = {};

export default function DefaultView({}: Props) {
  const { cloud_host } = useAppSelector(selectAuthInfo);
  return (
    <AnnouncementView>
      <Typography gutterBottom color="variant" variant="h6" component="h6">
        EPOS ERP System
      </Typography>
      <Typography
        gutterBottom
        color="variant.main"
        variant="body2"
        component="h6"
      >
        Please, Back to{" "}
        <Link className="highlight-link" href={cloud_host || "/"} passHref>
          EPOS Dashboard
        </Link>{" "}
        to Signin.
      </Typography>
    </AnnouncementView>
  );
}
