import { SvgPathProps, withMuiSvg } from "../withMuiSvg";

const Icon = ({
  contractColor: _contractColor,
  paletteColor: _paletteColor,
}: SvgPathProps) => {
  return (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 8l8 8 8-8"
    />
  );
};

Icon.displayName = "EposArrowDownIcon";

const EposArrowDownIcon = withMuiSvg(Icon, {
  viewBox: "0 0 24 24",
  width: 24,
  height: 24,
  fill: "none",
});

export default EposArrowDownIcon;
