"use client";

import type { ToastContainerProps } from "react-toastify";
import { ToastContainer as Toast, Slide } from "react-toastify";
import ToastCloseButton from "./ToastCloseButton";
import StyledToastWrapper from "./ToastContainer.styles";

const ToastContainer = ({ theme, ...props }: ToastContainerProps) => {
  return (
    <StyledToastWrapper>
      <Toast {...props} closeButton={ToastCloseButton} transition={Slide} />
    </StyledToastWrapper>
  );
};

export default ToastContainer;
