import { Box } from "@mui/material";
import React from "react";
import AnnouncementSection from "@/components/common/AnnouncementSection/AnnouncementSection";

type Props = {
  status?: number;
  children?: React.ReactNode;
  IconComponent?: React.ComponentType<any>;
  autoSizing?: boolean;
};

const AnnouncementView = ({
  status,
  children,
  IconComponent,
  autoSizing,
}: Props) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height={!autoSizing ? "calc(100vh - 64px)" : "auto"}
      minHeight={autoSizing ? "275px" : "auto"}
    >
      <AnnouncementSection IconComponent={IconComponent}>
        {children}
      </AnnouncementSection>
    </Box>
  );
};

export default AnnouncementView;
