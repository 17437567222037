import { RootState } from "@/store/store.config";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../types/user.model";
import { toast } from "react-toastify";
import { memoize } from "proxy-memoize";

interface AuthState {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  user: User | null;
  org: string | null;
  cloud_host: string | null;
}

const initialState: AuthState = {
  isAuthenticated: true,
  isAuthenticating: false,
  user: null,
  org: null,
  cloud_host: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess(
      state,
      action: PayloadAction<Omit<Partial<AuthState>, "isAuthenticated">>,
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
    logoutSuccess(state) {
      !!state.isAuthenticated && toast.success("Logout successfully");
      state.isAuthenticated = false;
      state.user = null;
      state.org = null;
    },
    verifyAuthTokenFailed(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.org = null;
    },
    authenticating(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = false;
      state.isAuthenticating = action.payload;
    },
  },
});

export const {
  loginSuccess,
  logoutSuccess,
  verifyAuthTokenFailed,
  authenticating,
} = authSlice.actions;
export const selectAuthInfo = memoize((state: RootState) => ({
  user: state.auth.user,
  org: state.auth.org || "",
  cloud_host: state.auth.cloud_host,
}));
export const selectIsAuthenticated = memoize(
  (state: RootState) => state.auth.isAuthenticated,
);
export const selectIsAuthenticating = memoize(
  (state: RootState) => state.auth.isAuthenticating,
);

const authReducer = authSlice.reducer;
export default authReducer;
