"use client";

import React from "react";
import DefaultView from "../components/DefaultView/DefaultView";

type Props = {};

export default function DefaultIndexView({}: Props) {
  return <DefaultView />;
}
