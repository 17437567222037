import { ProductVariant } from "@/features/Catalog/types/product.model";
import { RootState } from "@/store/store.config";
import {
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { memoize } from "proxy-memoize";
import {
  QuotationDto,
  MakePaymentDto,
  defaultQuotationCreateFields,
} from "../types/quotation.create.schema";
import { Quotation } from "../types/quotation.model";
import {
  QuotationListParams,
  defaultQuotationSearchFields,
} from "../types/quotation.search.schema";

export type TQuotationPhase = "show" | "edit" | "new";

export interface FormData<T> {
  data?: T;
  errors?: Record<keyof T, string>;
}

export type TQuotationFormPhaseData = {
  initializing: Quotation & QuotationDto;
  idling: Quotation & QuotationDto;
  modifying: Quotation & QuotationDto;
  submitting: Quotation & QuotationDto;
  sending_email: Quotation & QuotationDto;
  exporting_pdf: Quotation & QuotationDto;
  making_payment: FormData<MakePaymentDto>;
};
export type TQuotationFormPhase = keyof TQuotationFormPhaseData;

export interface QuotationState {
  quotationProductVariants: EntityState<ProductVariant>;
  quotationProcessingForm: (Quotation & QuotationDto) | undefined;
  makePaymentForm: FormData<MakePaymentDto>;
  filter: QuotationListParams;
  quotationPhase: TQuotationPhase;
  quotationFormPhase: TQuotationFormPhase;
  quotationDetailTabValue?: number;
  getQuotation: () => Quotation & QuotationDto;
}

export const quotationProductVariantAdapter =
  createEntityAdapter<ProductVariant>({
    selectId: (product) => {
      return product.id || product.sku;
    },
  });

export const quotationInitialState: QuotationState = {
  quotationProductVariants: quotationProductVariantAdapter.getInitialState(),
  quotationProcessingForm: undefined,
  makePaymentForm: {},
  quotationDetailTabValue: undefined,
  filter: {
    ...defaultQuotationSearchFields,
    page: 1,
    per_page: 25,
  },
  quotationPhase: "new",
  quotationFormPhase: "initializing",
  getQuotation: () => defaultQuotationCreateFields,
};

const quotationSlice = createSlice({
  name: "quotation",
  initialState: quotationInitialState,
  reducers: {
    productVariantsReceived(
      state,
      action: PayloadAction<{
        products: ProductVariant[];
        pharse?: TQuotationFormPhase;
      }>,
    ) {
      quotationProductVariantAdapter.setAll(
        state.quotationProductVariants,
        action.payload.products,
      );
      state.quotationFormPhase =
        action.payload.pharse || quotationInitialState.quotationFormPhase;
    },
    productVariantsRemoved(
      state,
      action: PayloadAction<ProductVariant["id"][]>,
    ) {
      quotationProductVariantAdapter.removeMany(
        state.quotationProductVariants,
        action.payload,
      );
    },
    setQuotationProcessingForm(
      state,
      action: PayloadAction<(Quotation & QuotationDto) | undefined>,
    ) {
      state.quotationProcessingForm = action.payload;
    },
    setFilter(state, action: PayloadAction<QuotationListParams>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    setQuotationFormPhase(
      state,
      action: PayloadAction<{
        phase: TQuotationFormPhase;
        data?: Partial<TQuotationFormPhaseData>;
      }>,
    ) {
      state.quotationFormPhase = action.payload.phase;
      if (action.payload.data) {
        switch (state.quotationFormPhase) {
          case "making_payment":
            state.makePaymentForm = {
              ...state.makePaymentForm,
              ...action.payload.data?.[state.quotationFormPhase],
            };
            break;

          default:
            if (!state?.quotationProcessingForm) {
              state.quotationProcessingForm =
                action.payload.data[state.quotationFormPhase];
            } else {
              state.quotationProcessingForm = {
                ...(state?.quotationProcessingForm || {}),
                ...action.payload.data[state.quotationFormPhase],
              };
            }
            break;
        }
      }
    },
    setGetQuotation(
      state,
      action: PayloadAction<() => Quotation & QuotationDto>,
    ) {
      state.getQuotation = action.payload;
    },
    setQuotationPhase(state, action: PayloadAction<TQuotationPhase>) {
      state.quotationPhase = action.payload;
    },
    setQuotationDetailTabValue(
      state,
      action: PayloadAction<number | undefined>,
    ) {
      state.quotationDetailTabValue = action?.payload;
    },
    resetQuotationState(state, action: PayloadAction<QuotationState>) {
      state = {
        ...state,
        ...(action?.payload || {}),
      };
    },
    resetFilter(
      state,
      action: PayloadAction<Partial<QuotationListParams> | undefined>,
    ) {
      const newState = action?.payload;
      state.filter = {
        ...state.filter,
        ...quotationInitialState.filter,
        ...newState,
      };
    },
    resetAdvanceFilter(
      state,
      action: PayloadAction<Partial<QuotationListParams> | undefined>,
    ) {
      const newState = action?.payload;
      state.filter = {
        ...state.filter,
        ...quotationInitialState.filter,
        ...newState,
        query_term: state.filter.query_term,
      };
    },
  },
});

export const {
  selectById: selectProductVariantById,
  selectIds: selectProductVariantIds,
  selectEntities: selectProductVariantEntities,
  selectAll: selectProductVariantList,
  selectTotal: selectTotalProductVariants,
} = quotationProductVariantAdapter.getSelectors(
  (state: RootState) => state.quotation.quotationProductVariants,
);

// Actions
export const quotationActions = quotationSlice.actions;

export const selectQuotationFilter = memoize(
  (state: RootState): QuotationListParams => state.quotation.filter,
);
export const selectQuotationPhase = memoize(
  (state: RootState): TQuotationPhase => state.quotation.quotationPhase,
);
export const selectIsShowPhase = memoize(
  (state: RootState): boolean => state.quotation.quotationPhase === "show",
);
export const selectIsEditPhase = memoize(
  (state: RootState): boolean => state.quotation.quotationPhase === "edit",
);
export const selectIsCreatePhase = memoize(
  (state: RootState): boolean => state.quotation.quotationPhase === "new",
);
export const selectDetailQuotationTabValue = memoize(
  (state: RootState): number | undefined =>
    state.quotation.quotationDetailTabValue,
);
export const selectQuotationProcessingForm = memoize(
  (state: RootState): (Quotation & QuotationDto) | undefined =>
    state.quotation?.quotationProcessingForm,
);
export const selectGetQuotation = memoize(
  (state: RootState): (() => Quotation & QuotationDto) =>
    state.quotation.getQuotation,
);

export const selectQuotationFormPhase = memoize(
  (state: RootState): TQuotationFormPhase => state.quotation.quotationFormPhase,
);

export const quotationSelectedProductSkuListSelector = createSelector(
  selectProductVariantList,
  (list) => list?.map((prod) => prod.sku) || [],
);

// Reducer
const quotationReducer = quotationSlice.reducer;
export default quotationReducer;
