import { RootState } from '@/store/store.config';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SummaryChartSearchParams, StatusChartSearchParams } from '../types/chart.schema';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { REPORT_DATE_FORMAT } from '@/components/formFields/DateTimeField/DateTimeField.constants';
import { ReportParams } from '@/types/common/baseReqRes';


export interface DashboardState {
  statusFilter: StatusChartSearchParams;
  summaryFilter: Omit<SummaryChartSearchParams, keyof ReportParams>;
}

export const dashboardInitialState: DashboardState = {
  statusFilter: {
    "start-date": format(startOfMonth(new Date), REPORT_DATE_FORMAT),
    "end-date": format(endOfMonth(new Date), REPORT_DATE_FORMAT),
    timezone: "Asia/Singapore"
  },
  summaryFilter: {
    range: "day",
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: dashboardInitialState,
  reducers: {
    setStatusFilter(state, action: PayloadAction<StatusChartSearchParams>) {
      state.statusFilter = {
        ...state.statusFilter,
        ...action.payload,
      };
    },
    setSummaryFilter(
      state,
      action: PayloadAction<Omit<SummaryChartSearchParams, keyof ReportParams>>,
    ) {
      state.summaryFilter = {
        ...state.summaryFilter,
        ...action.payload,
      };
    },
  },
});

// Actions
export const dashboardActions = dashboardSlice.actions;

export const selectStatusChartFilter = (state: RootState) =>
  state.dashboard.statusFilter;

export const selectSummaryChartFilter = (state: RootState) =>
  state.dashboard.summaryFilter;

// Reducer
const dashboardReducer = dashboardSlice.reducer;
export default dashboardReducer;
