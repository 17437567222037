import {
  CSSInterpolation,
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles";
import { InputRadius } from "@/public/styles/spacingConstants";
import { alpha } from "@mui/material";
import { baseInputColor } from "@/public/styles/colorConstants";

export const InputVariant: {
  defaultProps?: ComponentsProps["MuiInputBase"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiInputBase"];
  variants?: ComponentsVariants["MuiInputBase"];
} = {
  styleOverrides: {
    root: ({ theme, ownerState: { value, size } }) => ({
      borderRadius: InputRadius,
      fontSize: "1rem",
      "&::placeholder": {
        color: theme.palette.variant[30],
      },
      backgroundColor: value
        ? alpha(baseInputColor.backgroundColor as string, 0.5)
        : baseInputColor.backgroundColor,
      "&.MuiInputBase-noBackground": {
        backgroundColor: "transparent",
      },
    }),
    input: ({ theme, ownerState: { size } }) => {
      let tableCellSize: CSSInterpolation = {};
      let inputStyle: CSSInterpolation = {
        height: "auto",
        lineHeight: 1.5,
        padding: theme.spacing(1.5),
        overscrollBehavior: "contain",
      };
      let autoCompleteStyle: CSSInterpolation = {
        ...inputStyle,
        padding: theme.spacing(1.5, 0.5, 1.5, 1),
      };
      if (size === "table-cell") {
        tableCellSize = {
          fontSize: 14,
          lineHeight: "18px",
          padding: theme.spacing(0.875, 2, 0.875, 2),
        };
        return {
          "&.MuiInputBase-input.MuiAutocomplete-input": {
            ...autoCompleteStyle,
            ...tableCellSize,
            padding: `${theme.spacing(0.875, 1.5)} !important`,
          },
          "&.MuiInputBase-input": {
            ...inputStyle,
            ...tableCellSize,
          },
        };
      }

      

      return {
        "&.MuiInputBase-input.MuiAutocomplete-input": autoCompleteStyle,
        "&.MuiInputBase-input.MuiAutocomplete-input.MuiOutlinedInput-input":
          autoCompleteStyle,
        "&.MuiInputBase-input": inputStyle,
      };
    },
  },
};
