import {
  verifyAuthTokenFailed
} from "@/features/Auth/slices/auth.slice";
import { isErrorResponse } from "@/types/common/baseReqRes";
import type {
  Middleware,
  MiddlewareAPI,
  PayloadAction,
} from "@reduxjs/toolkit";
import { isRejectedWithValue } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action: PayloadAction<any>) => {
    const payload = action.payload;
    // skip logging error when Error Response of API slices have skipLogger is true
    if (payload?.skipLogger) return next(action);
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

    if (isRejectedWithValue(action) && isErrorResponse(payload)) {
      if (toast.isActive("session-invalid")) {
        return next(action);
      }

      if (payload.status === 401) {
        dispatch(verifyAuthTokenFailed());
        toast.error("Session Is Invalid!", {
          toastId: `session-invalid`,
        });
        return next(action);
      }

      if (!!payload.errors && payload.errors?.length > 0) {
        payload.errors.forEach((errItem) => {
          if (errItem.skipToast) return next(action)

          if (!toast.isActive(errItem?.source?.parameter || errItem.status)) {
            toast.error(errItem.detail, {
              toastId: `${errItem?.source?.parameter || errItem.status}_${Date.now()}`,
            });
          }
        });

        return next(action);
      }

      if (!toast.isActive(payload?.source?.parameter || payload.status)) {
        if (payload.skipToast) return next(action)

        toast.error(payload.message, {
          toastId: `${payload?.source?.parameter || payload.status}_${Date.now()}`,
        });
      }
    }

    return next(action);
  };
