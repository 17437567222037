export const GET_CATEGORY_OPTION_LIST = `/api/catalog/options/categories`;
export const GET_SUB_CATEGORY_OPTION_LIST = `/api/catalog/options/sub_categories`;

export const GET_PRODUCT_VARIANT_LIST = `/api/catalog/product_variants`;

export const GET_PRICING_RULE_LIST = `/api/catalog/pricing_rules`;

export const GET_STOCK_OF_VARIANT_LIST = `/api/catalog/variants`;

export const GET_TAX_RULE_OPTION_LIST = `/api/catalog/options/tax_rule`;
