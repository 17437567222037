import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles";

export const IconButtonVariant: {
  defaultProps?: ComponentsProps["MuiIconButton"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiIconButton"];
  variants?: ComponentsVariants["MuiIconButton"];
} = {
  styleOverrides: {
    root: {},
    sizeLarge: {
      height: "3rem",
      width: "3.5rem",
    },
    sizeMedium: {
      width: "2.5rem",
      height: "2.5rem",
    },
    sizeSmall: {
      width: "2rem",
      height: "2rem",
    },
  },
};
