import { BaseModel } from "@/types/common/base.model";
import { RecordData } from "@/types/common/baseReqRes";
import { decimalAdjustWithBigN, decimalAdjustWithBigNStr, priceBigNumber } from "@/utils/bigNumber";
import { Type } from "class-transformer";
import { IsBoolean, IsEnum, IsNumber, IsString, ValidateNested } from "class-validator";
import { PricingBook } from "./pricing_books.model";

export interface RecordPricingRule
  extends RecordData<PricingRule, "catalog_pricing_rule"> {}

export enum PricingRuleOperation {
  override = "override",
  percentage_markup = "percentage_markup",
  flat_markup = "flat_markup",
}



export class PricingRule extends BaseModel {
  @IsString()
  id: string = ''

  @IsString()
  pricing_book_id: string = "";

  @IsString()
  product_id: string = "";

  @IsString()
  selector: null = null;

  @IsNumber()
  value: number | string = 0;

  @IsBoolean()
  auto_apply: boolean = false;

  @IsNumber()
  override_value: number | string = 0;

  @IsNumber()
  different_value: number | string = 0;

  @IsString()
  @IsEnum(PricingRuleOperation)
  operation: PricingRuleOperation = PricingRuleOperation.override;

  @IsString()
  pricing_book_name: string = "";

  @IsBoolean()
  is_valid: boolean = true;

  private _pricing_book: PricingBook | null = null;

  @ValidateNested({ each: true })
  @Type(() => PricingBook)
  get pricing_book(): PricingBook | null {
    return this._pricing_book;
  }

  set pricing_book(value: PricingBook | null) {
    this._pricing_book = value;
    if (value) {
      this.pricing_book_name = value.name
    }
  }

  constructor(init?: Partial<PricingRule>) {
    super();
    Object.assign(this, init);
    if (init?.pricing_book) {
      this.pricing_book = init?.pricing_book
    }
  }

  calculateExtraPrice(unit_price: number | string) {
    const override_value = decimalAdjustWithBigN(priceBigNumber(this.override_value))
    const price = decimalAdjustWithBigN(priceBigNumber(unit_price))

    return decimalAdjustWithBigNStr(override_value.minus(price))
  }

  calculateOverrideValue(unit_price?: number | string) {
    if (typeof unit_price !== 'number' && typeof unit_price !== 'string' ) return 0

    const price = decimalAdjustWithBigN(priceBigNumber(unit_price))
    const value = decimalAdjustWithBigN(priceBigNumber(this.value))

    switch (this.operation) {
      case PricingRuleOperation.flat_markup:
        this.override_value = decimalAdjustWithBigNStr(price.plus(value))
        break;

      case PricingRuleOperation.percentage_markup:
        this.override_value = decimalAdjustWithBigNStr(price.multipliedBy((value.plus(100)).dividedBy(100)))
        break;

      case PricingRuleOperation.override:
        this.override_value = decimalAdjustWithBigNStr(value)
        break;
    
      default:
        break;
    }

    return this.override_value
  }
}

export type PricingRulePlain = Omit<PricingRule, '_pricing_book' | 'pricing_book' | 'getters' | 'setters' | 'any_methods'> & {
  pricing_book: PricingBook | null;
};

export interface PricingRuleOptionsParams {
  start_date: string,
  end_date: string,
  store_id: string;
  customer_id: string;
  product_variant_id: string;
}
