import { PaperRadius } from "@/public/styles/spacingConstants";
import {
  Box,
  Breakpoint,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  styled,
} from "@mui/material";
import {
  DIALOG_ACTION_MIN_HEIGHT,
  DIALOG_TITLE_MIN_HEIGHT,
} from "./ModalDialog.constants";

export const StyledDialog = styled(Dialog)(({ theme, maxWidth }) => ({
  ".MuiDialog-paper": {
    maxWidth: maxWidth === "sm" ? 512 : undefined,
    transition: "width 0.5s, height 0.5s",
  },
}));

export const PositionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  outline: 0,
}));

export const DialogWrapper = styled(Box)<{ size: Breakpoint }>(
  ({ theme, size }) => ({
    maxWidth: theme.breakpoints.values[size],
    width: "100%",
  }),
);

export const DialogTitleWrapper = styled(DialogTitle)(({ theme }) => ({
  minHeight: DIALOG_TITLE_MIN_HEIGHT,
  padding: theme.spacing(3),
}));

export const DialogTitleIcon = styled(Box)(({ theme }) => ({
  position: "relative",
  height: 100,
  marginBottom: theme.spacing(2),
  zIndex: 1,

  ["& > *"]: {
    position: "absolute",
    top: "0%",
    left: "50%",
    transform: `translate(-50%, -8.75rem)`,
  },
}));

export const DialogPaper = styled(Paper)(({ theme }) => ({
  borderRadius: PaperRadius,
}));

export const DialogWithIconTitleText = styled(Box)(({ theme }) => ({
  ...theme.typography.h5,
  textAlign: "center",
  position: "relative",
  zIndex: 2,
}));

export const DialogTitleText = styled(Box)(({ theme }) => ({
  ...theme.typography.h6,
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  minHeight: DIALOG_ACTION_MIN_HEIGHT,
  padding: theme.spacing(3),
}));

export const StyleClostIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  right: theme.spacing(3),
  zIndex: 2,

  color: theme.palette.grey[500],
}));
