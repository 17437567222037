"use client";

import React from "react";
import {
  alpha,
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "@/hooks/useAppSelector";
import {
  apiSliceCollection,
  clearFailedRequests,
  finishRetrying,
  startRetrying,
} from "@/features/App/slices/app.slice";
import { CustomCircularProgress } from "../CustomProgress/CustomCircularProgress";
import DialogTitle from "../ModalDialog/DialogTitle";
import EposNoInternetIcon from "@/public/icons/common/NoInternet.icon";
import { StyledRetryDialog } from "./RetryDialog.styles";

type Props = {};

const RetryDialog = (props: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const failedRequests = useAppSelector(
    (state) => state.appState.failedRequests,
  );

  const isRetrying = useAppSelector((state) => state.appState.isRetrying);

  const handleRetry = async () => {
    dispatch(startRetrying());

    // Retry each failed request generically
    // eslint-disable-next-line consistent-return
    const allRequestPromises = failedRequests.map(async (request) => {
      const {
        endpointName,
        args,
        fixedCacheKey,
        method,
        requestId,
        sliceName,
      } = request;
      const apiSlice = (apiSliceCollection as any)[sliceName];

      // Check if the request is a mutation
      if (method === "mutation" && apiSlice) {
        try {
          // Call the mutation directly using dispatch
          dispatch(
            apiSlice.internalActions.removeMutationResult({
              requestId,
              fixedCacheKey,
            }),
          );

          const response = await dispatch(
            apiSlice.endpoints[endpointName].initiate(args, {
              fixedCacheKey,
            }),
          ).unwrap();
          return response; // Capture success response
        } catch (error) {
          return error; // Capture error for handling
        }
      }

      // You can also handle queries here if needed
      if (method === "query" && apiSlice) {
        try {
          const response = await dispatch(
            apiSlice.endpoints[endpointName].initiate(args),
          ).unwrap();
          return response; // Capture success response
        } catch (error) {
          return error; // Capture error for handling
        }
      }
    });
    toast.dismiss();
    dispatch(clearFailedRequests());

    await Promise.all(allRequestPromises);

    dispatch(finishRetrying());
  };

  const handleCancel = () => {
    dispatch(clearFailedRequests());
  };

  return (
    <>
      <StyledRetryDialog
        fullWidth
        maxWidth="md"
        open={failedRequests.length > 0}
        onClose={handleCancel}
      >
        <DialogTitle id="" onClose={handleCancel}>
          Network or Server Unavailable
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            minHeight={250}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop={1}
            marginBottom={4}
          >
            <EposNoInternetIcon />
            <Typography
              textAlign="center"
              lineHeight={1.5}
              variant="body1"
              fontWeight={500}
            >
              Oops, looks like there’s no internet connection.
              <br />
              Please check your network and try again!
            </Typography>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{
            justifyContent: "space-between",
            padding: (theme) => theme.spacing(2, 3),
          }}
        >
          <Button variant="outlined" color="variant" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleRetry}>
            Retry
          </Button>
        </DialogActions>
      </StyledRetryDialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={isRetrying}
      >
        <Box textAlign="center">
          <CustomCircularProgress
            id="retry-dialog"
            size={100}
            thickness={5}
            variant="indeterminate"
            gradiant={{
              "0%": theme.palette.success[20],
              "60%": theme.palette.success[5],
              "80%": alpha(theme.palette.success[5] as any, 0.8),
              "100%": alpha(theme.palette.success[5] as any, 0.5),
            }}
          />
          <Typography marginTop={2} variant="h4">
            Retrying, please wait...
          </Typography>
        </Box>
      </Backdrop>
    </>
  );
};

export default RetryDialog;
