"use client";

import { PaletteColor, SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import { get } from "lodash";
import React, { FC, useMemo } from "react";

export type TEposIconVariants = "eposPrimary" | "eposSecondary" | "eposOutline";
export interface SvgPathProps extends Omit<SvgIconProps, "fontSize"> {
  fontSize?: SvgIconProps["fontSize"] | string;
  contractColor?: string;
  paletteColor?:
    | string
    | (Partial<PaletteColor> & {
        contractColor?: string;
      });
}

export function withMuiSvg(
  WrappedComponent: FC<SvgPathProps>,
  preProps?: Partial<SvgPathProps>,
) {
  const {
    color: preColor,
    fontSize: preFontSize,
    fill: preFill,
    stroke: preStroke,
    ...restPreProps
  } = preProps || {};
  // Try to create a nice displayName for React Dev Tools.
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "CustomMuiSvg";

  const ComponentWithMuiSvg = React.forwardRef(
    (
      {
        color: propColor,
        fontSize: propFontSize,
        fill: propFill,
        stroke: propStroke,
        ...props
      }: SvgPathProps,
      ref: any,
    ) => {
      const { palette, components } = useTheme();

      const color = useMemo(() => {
        return propColor || preColor;
      }, [propColor]);

      const paletteColor = useMemo<{
        color?: SvgPathProps["paletteColor"] | string;
        contractColor: string;
      }>(() => {
        if (color === "inherit" || color === "action" || !color)
          return {
            contractColor:
              (components?.MuiSvgIcon?.styleOverrides
                ?.colorPrimary as string) || "",
          };

        if (color === "disabled") {
          return {
            contractColor:
              (components?.MuiSvgIcon?.styleOverrides
                ?.colorDisabled as string) || "",
          };
        }

        const _paletteColor = (get(palette, color) ?? {}) as PaletteColor;

        if (typeof _paletteColor === "object") {
          return {
            color: _paletteColor,
            contractColor: _paletteColor?.contrastText,
          };
        }

        const { contrastText } = get(palette, color.split(".")[0]) ?? {};

        return {
          color: _paletteColor as string,
          contractColor: contrastText,
        };
      }, [palette, color, components?.MuiSvgIcon?.styleOverrides]);

      const fontSize = useMemo(() => {
        const _fontSize = propFontSize || preFontSize;

        if (["small", "inherit", "large", "medium"].includes(_fontSize || "")) {
          return {
            spec: _fontSize,
          };
        }

        return {
          spec: _fontSize,
          isSpecifySpec: true,
        };
      }, [propFontSize]);

      const fill = useMemo(() => {
        return propFill || preFill;
      }, [propFill]);

      const stroke = useMemo(() => {
        return propStroke || preStroke;
      }, [propStroke]);

      const styleColor = useMemo(
        () =>
          typeof paletteColor.color === "string"
            ? paletteColor.color
            : undefined,
        [paletteColor.color],
      );

      return (
        <SvgIcon
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          ref={ref}
          {...restPreProps}
          {...props}
          color={color}
          {...(!fontSize?.isSpecifySpec
            ? {
                fontSize: fontSize?.spec as any,
                sx: {
                  fill,
                  stroke,
                  color: styleColor,
                  ...((restPreProps?.sx || {}) as any),
                  ...(props?.sx || {}),
                },
              }
            : {
                sx: {
                  fontSize: fontSize?.spec as any,
                  fill,
                  stroke,
                  color: styleColor,
                  ...((restPreProps?.sx || {}) as any),
                  ...(props?.sx || {}),
                },
              })}
        >
          <WrappedComponent
            {...props}
            contractColor={paletteColor?.contractColor}
            paletteColor={paletteColor.color}
          />
        </SvgIcon>
      );
    },
  );

  ComponentWithMuiSvg.displayName = `withMuiSvg_(${displayName})`;
  return ComponentWithMuiSvg;
}
