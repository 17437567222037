const BoxShadowConstants = {
  "--shadow-primary-right": "7px 8px 16px rgba(191, 211, 255, 0.3)",
  "--shadow-primary-left": "-7px -8px 16px rgba(191, 211, 255, 0.3)",
  "--shadow-primary-bottom": "0 16px 13px rgba(191, 211, 255, 0.5)",
  "--shadow-primary-top": "0 -16px 13px rgba(191, 211, 255, 0.5)",
  "--shadow-primary-center": "0 0px 13px rgba(191, 211, 255, 0.5)",
  "--shadow-secondary-right": "7px 8px 16px rgba(191, 211, 255, 0.3)",
  "--shadow-secondary-left": "-7px -8px 16px rgba(207, 218, 236, 0.3)",
  "--shadow-secondary-bottom": "0 16px 13px rgba(191, 211, 255, 0.3)",
  "--shadow-secondary-top": "0 -16px 13px rgba(207, 218, 236, 0.5)",
  "--shadow-secondary-center": "0 0px 13px rgba(191, 211, 255, 0.5)",
  "--shadow-secondary-center-for-input": "0 0px 20px rgba(191, 211, 255, 0.5)",
  "--shadow-secondary-center-for-input-custom": "0 0px 20px",
};

export default BoxShadowConstants;
