import { ListParams } from '../../../types/common/baseReqRes';
import { DATE_FORMAT } from "@/components/formFields/DateTimeField/DateTimeField.constants";
import { DATE_CHAR_LENGTH_MSG, DATE_FORMAT_ERROR_MSG } from "@/public/constants/form";
import { isMatch } from "date-fns";
import * as yup from 'yup';
import { TDeliveryOrderCSVType, TDeliveryOrderStatus } from "./deliveryOrder.model";

export const DeliveryOrderSearchFields = {
  queryTerm: "query_term",
  store_id: "store_id",
  invoice_id: "invoice_id",
  scheduledFrom: "schedule_from",
  scheduledTo: "schedule_to",
  status: "status",
  csv_type: "csv_type",
} as const

export interface DeliveryOrderListParams extends ListParams {
  [DeliveryOrderSearchFields.queryTerm]: string
  [DeliveryOrderSearchFields.scheduledFrom]: string | null,
  [DeliveryOrderSearchFields.scheduledTo]: string | null,
  [DeliveryOrderSearchFields.store_id]: string | null,
  [DeliveryOrderSearchFields.invoice_id]: string | null,
  [DeliveryOrderSearchFields.status]: TDeliveryOrderStatus | null,
  [DeliveryOrderSearchFields.csv_type]: TDeliveryOrderCSVType,
}

export interface DeliveryOrderListOfInvoiceParams extends ListParams {
  invoice_id: string;
}

export interface DeliveryOrderSearchDto
  extends Omit<DeliveryOrderListParams, keyof ListParams> {}

export const defaultDeliveryOrderSearchFields: DeliveryOrderSearchDto = {
  query_term: "",
  store_id: null,
  invoice_id: null,
  schedule_from: null,
  schedule_to: null,
  status: null,
  csv_type: 'standard'
}

export const deliveryOrderSearchSchema = yup.object().shape({
  [DeliveryOrderSearchFields.queryTerm]: yup.string(),
  [DeliveryOrderSearchFields.scheduledFrom]: yup
  .string().nullable()
  .max(20, 'Invoice Date' + DATE_CHAR_LENGTH_MSG)
  .test('Date format', (value, ctx) => {
    if (!value) return true;
    return isMatch(value, DATE_FORMAT) || ctx.createError({ message: DATE_FORMAT_ERROR_MSG });
  }),
  [DeliveryOrderSearchFields.scheduledTo]: yup
  .string().nullable()
  .max(20, 'Invoice Date' + DATE_CHAR_LENGTH_MSG)
  .test('Date format', (value, ctx) => {
    if (!value) return true;
    return isMatch(value, DATE_FORMAT) || ctx.createError({ message: DATE_FORMAT_ERROR_MSG });
  }),
  [DeliveryOrderSearchFields.store_id]: yup
  .string().nullable(),
  [DeliveryOrderSearchFields.status]: yup
  .string().nullable()
});
