"use client";

import { useLayoutEffect } from "react";
import { initClarity } from "@/utils/microsoft-clarity";

const Clarity = () => {
  useLayoutEffect(() => {
    initClarity(process.env.NEXT_PUBLIC_CLARITY_PROJECT_ID);
  }, []);

  return null;
};

export default Clarity;
