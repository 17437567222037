import EposCloseIcon from "@/public/icons/common/Close.icon";
import { IDialogTitleProps } from "./ModalDialog.props";
import {
  DialogTitleText,
  DialogTitleWrapper,
  StyleClostIconButton,
} from "./ModalDialog.styles";

const DialogTitle = ({ children, onClose, ...props }: IDialogTitleProps) => {
  return (
    <DialogTitleWrapper sx={{ m: 0 }} {...props}>
      <DialogTitleText>{children}</DialogTitleText>
      {onClose ? (
        <StyleClostIconButton aria-label="close" onClick={() => onClose()}>
          <EposCloseIcon />
        </StyleClostIconButton>
      ) : null}
    </DialogTitleWrapper>
  );
};

export default DialogTitle;
