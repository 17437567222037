import { ProductVariant } from "@/features/Catalog/types/product.model";
import { RootState } from "@/store/store.config";
import {
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { memoize } from "proxy-memoize";
import {
  InvoiceDto,
  MakePaymentDto,
  defaultInvoiceCreateFields,
} from "../types/invoice.create.schema";
import { Invoice } from "../types/invoice.model";
import {
  InvoiceListParams,
  defaultInvoiceSearchFields,
} from "../types/invoice.search.schema";

export type TInvoicePhase = "show" | "edit" | "new";

export interface FormData<T> {
  data?: T;
  errors?: Record<keyof T, string>;
}

export type TInvoiceFormPhaseData = {
  initializing: Invoice & InvoiceDto;
  idling: Invoice & InvoiceDto;
  modifying: Invoice & InvoiceDto;
  submitting: Invoice & InvoiceDto;
  sending_email: Invoice & InvoiceDto;
  exporting_pdf: Invoice & InvoiceDto;
  making_payment: FormData<MakePaymentDto>;
};
export type TInvoiceFormPhase = keyof TInvoiceFormPhaseData;

export interface InvoiceState {
  invoiceProductVariants: EntityState<ProductVariant>;
  makePaymentForm: FormData<MakePaymentDto>;
  filter: InvoiceListParams;
  invoicePhase: TInvoicePhase;
  invoiceFormPhase: TInvoiceFormPhase;
  invoiceDetailTabValue?: number;
  getInvoice: () => Invoice & InvoiceDto;
}

export const invoiceProductVariantAdapter = createEntityAdapter<ProductVariant>(
  {
    selectId: (product) => {
      return product.id || product.sku;
    },
  },
);

export const invoiceInitialState: InvoiceState = {
  invoiceProductVariants: invoiceProductVariantAdapter.getInitialState(),
  makePaymentForm: {},
  invoiceDetailTabValue: undefined,
  filter: {
    ...defaultInvoiceSearchFields,
    page: 1,
    per_page: 25,
  },
  invoicePhase: "new",
  invoiceFormPhase: "initializing",
  getInvoice: () => defaultInvoiceCreateFields,
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: invoiceInitialState,
  reducers: {
    productVariantsReceived(
      state,
      action: PayloadAction<{
        products: ProductVariant[];
        pharse?: TInvoiceFormPhase;
      }>,
    ) {
      invoiceProductVariantAdapter.setAll(
        state.invoiceProductVariants,
        action.payload.products,
      );
      state.invoiceFormPhase =
        action.payload.pharse || invoiceInitialState.invoiceFormPhase;
    },
    productVariantsRemoved(
      state,
      action: PayloadAction<ProductVariant["id"][]>,
    ) {
      invoiceProductVariantAdapter.removeMany(
        state.invoiceProductVariants,
        action.payload,
      );
    },
    setFilter(state, action: PayloadAction<InvoiceListParams>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    setGetInvoice(state, action: PayloadAction<() => Invoice & InvoiceDto>) {
      state.getInvoice = action.payload;
    },
    setInvoiceFormPhase(
      state,
      action: PayloadAction<{ phase: TInvoiceFormPhase }>,
    ) {
      state.invoiceFormPhase = action.payload.phase;
    },
    setInvoicePhase(state, action: PayloadAction<TInvoicePhase>) {
      state.invoicePhase = action.payload;
    },
    setInvoiceDetailTabValue(state, action: PayloadAction<number | undefined>) {
      state.invoiceDetailTabValue = action?.payload;
    },
    resetInvoiceState(state, action: PayloadAction<InvoiceState>) {
      state = {
        ...state,
        ...(action?.payload || {}),
      };
    },
    resetFilter(
      state,
      action: PayloadAction<Partial<InvoiceListParams> | undefined>,
    ) {
      const newState = action?.payload;
      state.filter = {
        ...state.filter,
        ...invoiceInitialState.filter,
        ...newState,
      };
    },
    resetAdvanceFilter(
      state,
      action: PayloadAction<Partial<InvoiceListParams> | undefined>,
    ) {
      const newState = action?.payload;
      state.filter = {
        ...state.filter,
        ...invoiceInitialState.filter,
        ...newState,
        query_term: state.filter.query_term,
      };
    },
  },
});

export const {
  selectById: selectProductVariantById,
  selectIds: selectProductVariantIds,
  selectEntities: selectProductVariantEntities,
  selectAll: selectProductVariantList,
  selectTotal: selectTotalProductVariants,
} = invoiceProductVariantAdapter.getSelectors(
  (state: RootState) => state.invoice.invoiceProductVariants,
);

// Actions
export const invoiceActions = invoiceSlice.actions;

export const selectInvoiceFilter = memoize(
  (state: RootState): InvoiceListParams => state.invoice.filter,
);
export const selectInvoicePhase = memoize(
  (state: RootState): TInvoicePhase => state.invoice.invoicePhase,
);
export const selectIsShowPhase = memoize(
  (state: RootState): boolean => state.invoice.invoicePhase === "show",
);
export const selectIsEditPhase = memoize(
  (state: RootState): boolean => state.invoice.invoicePhase === "edit",
);
export const selectIsCreatePhase = memoize(
  (state: RootState): boolean => state.invoice.invoicePhase === "new",
);
export const selectDetailInvoiceTabValue = memoize(
  (state: RootState): number | undefined => state.invoice.invoiceDetailTabValue,
);
export const selectGetInvoice = memoize(
  (state: RootState): (() => Invoice & InvoiceDto) => state.invoice.getInvoice,
);

export const selectInvoiceFormPhase = memoize(
  (state: RootState): TInvoiceFormPhase => state.invoice.invoiceFormPhase,
);

export const invoiceSelectedProductSkuListSelector = createSelector(
  selectProductVariantList,
  (list) => list?.map((prod) => prod.sku) || [],
);

// Reducer
const invoiceReducer = invoiceSlice.reducer;
export default invoiceReducer;
