import { DATE_FORMAT } from "@/components/formFields/DateTimeField/DateTimeField.constants";
import {
  DATE_AFTER_MSG,
  DATE_CHAR_LENGTH_MSG,
  DATE_FORMAT_ERROR_MSG,
} from "@/public/constants/form";
import { compareAsc, isMatch, parse } from "date-fns";
import * as yup from "yup";
import { TInvoiceStatus } from "./invoice.model";
import { ListParams } from "@/types/common/baseReqRes";

export const InvoiceSearchFields = {
  queryTerm: "query_term",
  store: "store",
  invoiceStartDate: "invoice_start_date",
  invoiceEndDate: "invoice_end_date",
  dueStartDate: "due_start_date",
  dueEndDate: "due_end_date",
  status: "status",
  showVoided: "show_voided",
} as const;

export interface InvoiceListParams extends ListParams {
  [InvoiceSearchFields.queryTerm]: string;
  [InvoiceSearchFields.invoiceStartDate]: string | null;
  [InvoiceSearchFields.invoiceEndDate]: string | null;
  [InvoiceSearchFields.dueStartDate]: string | null;
  [InvoiceSearchFields.dueEndDate]: string | null;
  [InvoiceSearchFields.store]: string | null;
  [InvoiceSearchFields.status]: TInvoiceStatus | null;
  [InvoiceSearchFields.showVoided]: boolean;
}

export interface InvoiceSearchDto
  extends Omit<InvoiceListParams, keyof ListParams> {}

export const defaultInvoiceSearchFields: InvoiceSearchDto = {
  query_term: "",
  store: null,
  invoice_start_date: null,
  invoice_end_date: null,
  due_start_date: null,
  due_end_date: null,
  status: null,
  show_voided: false,
};

export const invoiceSearchSchema = yup.object().shape({
  [InvoiceSearchFields.queryTerm]: yup.string(),
  [InvoiceSearchFields.invoiceStartDate]: yup
    .string()
    .nullable()
    .max(20, "Invoice Date" + DATE_CHAR_LENGTH_MSG)
    .test("Date format", (value, ctx) => {
      if (!value) return true;
      return (
        isMatch(value, DATE_FORMAT) ||
        ctx.createError({ message: DATE_FORMAT_ERROR_MSG })
      );
    }),
  [InvoiceSearchFields.invoiceEndDate]: yup
    .string()
    .nullable()
    .max(20, "Invoice Date" + DATE_CHAR_LENGTH_MSG)
    .test("Date format", (value, ctx) => {
      if (!value) return true;
      return (
        isMatch(value, DATE_FORMAT) ||
        ctx.createError({ message: DATE_FORMAT_ERROR_MSG })
      );
    })
    .when(
      InvoiceSearchFields.invoiceStartDate,
      (fieldValues: string[], field, endDateField) => {
        const startDate = fieldValues[0]
          ? parse(fieldValues[0], DATE_FORMAT, new Date())
          : null;
        const endDate = endDateField?.value
          ? parse(endDateField.value, DATE_FORMAT, new Date())
          : null;
        if (endDate && startDate) {
          const endDateIsAfter = compareAsc(endDate, startDate);

          if (endDateIsAfter < 0) {
            return field.test("Validate Date Is After", (val, ctx) =>
              ctx.createError({
                message: `Invoice End Date ${DATE_AFTER_MSG} Invoice Start Date`,
              }),
            );
          }
        }

        return field;
      },
    ),
  [InvoiceSearchFields.dueStartDate]: yup
    .string()
    .nullable()
    .max(20, "Invoice Date" + DATE_CHAR_LENGTH_MSG)
    .test("Date format", (value, ctx) => {
      if (!value) return true;
      return (
        isMatch(value, DATE_FORMAT) ||
        ctx.createError({ message: DATE_FORMAT_ERROR_MSG })
      );
    }),
  [InvoiceSearchFields.dueEndDate]: yup
    .string()
    .nullable()
    .max(20, "Invoice Date" + DATE_CHAR_LENGTH_MSG)
    .test("Date format", (value, ctx) => {
      if (!value) return true;
      return (
        isMatch(value, DATE_FORMAT) ||
        ctx.createError({ message: DATE_FORMAT_ERROR_MSG })
      );
    })
    .when(
      InvoiceSearchFields.dueStartDate,
      (fieldValues: string[], field, endDateField) => {
        const startDate = fieldValues[0]
          ? parse(fieldValues[0], DATE_FORMAT, new Date())
          : null;
        const endDate = endDateField?.value
          ? parse(endDateField.value, DATE_FORMAT, new Date())
          : null;
        if (endDate && startDate) {
          const endDateIsAfter = compareAsc(endDate, startDate);

          if (endDateIsAfter < 0) {
            return field.test("Validate Date Is After", (val, ctx) =>
              ctx.createError({
                message: `Due End Date ${DATE_AFTER_MSG} Due Start Date`,
              }),
            );
          }
        }

        return field;
      },
    ),
  [InvoiceSearchFields.store]: yup.string().nullable(),
  [InvoiceSearchFields.status]: yup.string().nullable(),
});
