import { PaperRadius } from "@/public/styles/spacingConstants";
import { Box, Breakpoint, Card, Paper, styled } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

export const AnoucementWrapper = styled(Card)<{ size: Breakpoint }>(
  ({ theme, size }) => ({
    maxWidth: theme.breakpoints.values[size],
    minWidth: "15rem",
    margin: 0,
    padding: theme.spacing(2),
    overflow: "visible",
  }),
);

export const AnoucementTitleIcon = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "6.25rem",
  zIndex: 1,

  ["& > *"]: {
    position: "absolute",
    top: "0%",
    left: "50%",
    transform: `translate(-50%, -7.5rem)`,
  },
}));

export const AnoucementPaper = styled(Paper)(({ theme }) => ({
  borderRadius: PaperRadius,
}));

export const AnoucementTitleText = styled(Box)(({ theme }) => ({
  ...theme.typography.h5,
  textAlign: "center",
  position: "relative",
  zIndex: 2,
}));
