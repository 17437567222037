"use client";

import styled from "@emotion/styled";
import { ToastContainer } from "react-toastify";
import type { ToastContainerProps } from "react-toastify";
import {
  ErrorColors,
  PrimaryColors,
  SuccessColors,
  VariantColors,
  WarningColors,
} from "@/public/styles/colorConstants";
import { Box } from "@mui/material";

const StyledToastWrapper = styled(Box)`
  display: "content";

  & .Toastify__toast-container {
    width: fit-content;
    max-width: 90%;
    right: 0;
    left: auto;

    ${(props) => props.theme.breakpoints.up("md")} {
      max-width: 50vw;
    }

    ${(props) => props.theme.breakpoints.up("xl")} {
      max-width: 35vw;
    }

    & .Toastify__toast {
      background-color: #ffffff;
      padding: 0;
      border-radius: 0.5rem;
      box-shadow: var(--shadow-secondary-center);

      & .Toastify__toast-body {
        margin: 0;
        border-left: 0.5rem solid;
        padding: 1rem 2.5rem 1rem 0.5rem;

        & .MuiButton-root {
          font-size: 1rem;
          padding: 0.5rem 1.5rem;
          line-height: 1.25;
        }
      }

      & .close-box {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0.25rem;
        top: 0;
        bottom: 0;
      }

      &.Toastify__toast--success {
        & .Toastify__toast-body {
          background-color: ${SuccessColors.Success05}10;
          color: ${SuccessColors.Success90};
          border-left-color: ${SuccessColors.Success30};

          & .Toastify__toast-icon svg {
            fill: ${SuccessColors.Success30};
          }

          & .MuiButton-root {
            background-color: ${SuccessColors.Success30};
          }
        }

        & .Toastify__progress-bar--success {
          background-color: ${SuccessColors.Success30};
        }
      }

      &.Toastify__toast--warning {
        & .Toastify__toast-body {
          background-color: ${WarningColors.Warning10}10;
          color: ${WarningColors.Warning90};
          border-left-color: ${WarningColors.Warning30};

          & .Toastify__toast-icon svg {
            fill: ${WarningColors.Warning30};
          }

          & .MuiButton-root {
            background-color: ${WarningColors.Warning30};
          }
        }

        & .Toastify__progress-bar--warning {
          background-color: ${WarningColors.Warning30};
        }
      }

      &.Toastify__toast--error {
        & .Toastify__toast-body {
          background-color: ${ErrorColors.Error10}10;
          color: ${ErrorColors.Error90};
          border-left-color: ${ErrorColors.Error30};

          & .Toastify__toast-icon svg {
            fill: ${ErrorColors.Error30};
          }

          & .MuiButton-root {
            background-color: ${ErrorColors.Error30};
          }
        }

        & .Toastify__progress-bar--error {
          background-color: ${ErrorColors.Error30};
        }
      }

      &.Toastify__toast--info {
        & .Toastify__toast-body {
          background-color: ${VariantColors.Variant80};
          color: #ffffff;
          border-left-color: ${VariantColors.Variant80};

          & .Toastify__toast-icon svg {
            fill: #ffffff;
          }

          & .MuiButton-root {
            background-color: transparent;
            color: ${PrimaryColors.Primary05};
          }
        }

        & .Toastify__progress-bar--info {
          background-color: #ffffff;
        }

        & svg {
          fill: #ffffff;
        }
      }
    }
  }
`;

export default StyledToastWrapper;
