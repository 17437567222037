"use client";

import RetryDialog from "@/components/common/RetryDialog/RetryDialog";
import { ToastContainer } from "@/components/common/ToastContainer";
import { DefaultLayout } from "@/layout/DefaultLayout/DefaultLayout";
import ThemeRegistry from "@/public/theme/ThemeRegistry";
import StoreProvider from "@/store/StoreProvider";
import { ComponentWithChildren } from "@/types/common/base";

const Providers: React.FC<ComponentWithChildren> = ({ children }) => {
  return (
    <ThemeRegistry>
      <StoreProvider>
        <DefaultLayout>{children}</DefaultLayout>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <RetryDialog />
      </StoreProvider>
    </ThemeRegistry>
  );
};

export default Providers;
