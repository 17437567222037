import { REQUIRED_MSG } from "@/public/constants/form";
import * as yup from "yup";

const appYup = yup;

appYup.setLocale({
  mixed: {
    required: `\${label} ${REQUIRED_MSG}`,
  },
});

export default appYup;
