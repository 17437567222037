import { ErrorResponse } from "@/types/common/baseReqRes";
import { BaseQueryFn, retry } from "@reduxjs/toolkit/dist/query";
import { RefetchConfigOptions } from "@reduxjs/toolkit/dist/query/core/apiState";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const axiosClient = axios.create({
  // baseURL: `${process.env?.NODE_ENV === 'production' ? 'https' : 'http'}://${process?.env?.['HOST'] || 'lvh.me'}:${process?.env?.['PORT'] || 3000}`,
  headers: {
    "Content-Type": "application/json",
    Accept: " application/json",
  },
});

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" },
  ): BaseQueryFn<
    {
      url: string;
      method: "get" | "post" | "put" | "patch" | "delete";
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
      restConfig?: AxiosRequestConfig;
      responseHandler?: (res: AxiosResponse) => void;
    } | undefined,
    unknown,
    ErrorResponse,
    Partial<RefetchConfigOptions> | {}
  > =>
  async (query) => {
    if (!query) return {
      data: null
    }

    const {
      url,
      method,
      data,
      params,
      headers,
      restConfig,
      responseHandler,
    } = query

    try {
      const result = await axiosClient({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
        ...restConfig,
      });
      if (!!responseHandler) {
        await responseHandler(result);
        return { data: null };
      }

      return { data: result };
    } catch (axiosError) {
      const err = axiosError as AxiosError<ErrorResponse>;
      let status = err.response?.status || err.status || 400

      if (err.code === "ERR_NETWORK") {
        status = 503
      }

      return {
        error: {
          status,
          message: err.response?.data.message || err.message,
          errors: err.response?.data.errors || [],
          source: err.response?.data.source,
          rawError: err.message,
        },
      };
    }
  };

export const axiosBaseQueryWithRetry = retry(axiosBaseQuery({ baseUrl: "" }), {
  maxRetries: 0,
  backoff: async (attempt, maxRetries) => {
    const attempts = Math.min(attempt, maxRetries);
    const timeout = attempts * 1000; // Scale up by 1000ms per request
    await new Promise((resolve) =>
      setTimeout((res: any) => resolve(res), timeout),
    );
  },
});

export default axiosClient;
