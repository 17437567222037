import { SvgPathProps, withMuiSvg } from "../withMuiSvg";

const Icon = ({
  contractColor: _contractColor,
  paletteColor: _paletteColor,
}: SvgPathProps) => {
  return (
    <>
      <rect
        width="22.296"
        height="3.017"
        rx="1.508"
        transform="rotate(44.91 1.068 2.585)"
      />
      <rect
        width="22.296"
        height="3.017"
        rx="1.508"
        transform="rotate(135.09 8.533 4.85)"
      />
    </>
  );
};

Icon.displayName = "EposCloseIcon";

const EposCloseIcon = withMuiSvg(Icon, {
  viewBox: "0 0 18 18",
  width: 18,
  height: 18,
  color: "variant.light",
  stroke: "none",
  fontSize: "1rem",
});

export default EposCloseIcon;
