export const REQUIRED_MSG = " is required";
export const INVALID_EMAIL_MSG = "is invalid";
export const DATE_FORMAT_ERROR_MSG = "Date is not match with format dd/MM/yyyy";
export const DATE_CHAR_LENGTH_MSG = " must be at most 20 characters";
export const MIN_LIST_MSG = "Need at least 1 ";
export const DATE_AFTER_MSG = "must be on or after";
export const MIN_NUMBER_MSG = "must be greater than or equal to 0";
export const DISC_AMOUNT_LARGER_MSG = "Larger than Amount";

export const qtyFieldCellDigits = 5;
export const priceFieldCellDigits = 10;
