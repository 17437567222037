import {
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityAdapter,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  ProductVariant,
  ProductVariantListParams,
} from "../types/product.model";
import { RootState } from "@/store/store.config";
import { memoize } from "proxy-memoize";

export interface ProductVariantState {
  filter: ProductVariantListParams;
  selectedProductVariants: EntityState<ProductVariant>;
}

export const selectedProductVariantsAdapter =
  createEntityAdapter<ProductVariant>({
    selectId: (product) => {
      return product.id || product.sku;
    },
  });

export const productVariantInitialState: ProductVariantState = {
  filter: {
    term: "",
    category_id: null,
    sub_category_id: null,
    page: 1,
    per_page: 50,
  },
  selectedProductVariants: selectedProductVariantsAdapter.getInitialState(),
};

const productVariantSlice = createSlice({
  name: "productVariant",
  initialState: productVariantInitialState,
  reducers: {
    setFilter(state, action: PayloadAction<ProductVariantListParams>) {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    productVariantsReceived(state, action: PayloadAction<ProductVariant[]>) {
      selectedProductVariantsAdapter.setAll(
        state.selectedProductVariants,
        action.payload,
      );
    },
    productVariantsRemoved(
      state,
      action: PayloadAction<ProductVariant["id"][]>,
    ) {
      selectedProductVariantsAdapter.removeMany(
        state.selectedProductVariants,
        action.payload,
      );
    },
    resetFilter(
      state,
      action: PayloadAction<ProductVariantListParams | undefined>,
    ) {
      state.filter = action?.payload || productVariantInitialState.filter;
    },
    resetAdvanceFilter(
      state,
      action: PayloadAction<ProductVariantListParams | undefined>,
    ) {
      state.filter = {
        ...(action?.payload || productVariantInitialState.filter),
        term: state.filter.term,
      };
    },
  },
});

// Actions
export const productVariantActions = productVariantSlice.actions;

export const selectProductVariantFilter = (state: RootState) =>
  state.productVariant.filter;

export const {
  selectById: selectProductVariantById,
  selectIds: selectProductVariantIds,
  selectEntities: selectProductVariantEntities,
  selectAll: selectProductVariantList,
  selectTotal: selectTotalProductVariants,
} = selectedProductVariantsAdapter.getSelectors(
  (state: RootState) => state.productVariant.selectedProductVariants,
);

const emptyList: any[] = [];

export const selectedProductSkuListSelector = createSelector(
  selectProductVariantList,
  memoize((list) => list?.map((prod) => prod.sku) || emptyList),
);

// Reducer
const productVariantReducer = productVariantSlice.reducer;
export default productVariantReducer;
