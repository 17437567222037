"use client";

import { debounce } from "lodash";
import { useCallback, useLayoutEffect } from "react";

const AdjustHeightOnMobile = () => {
  const handleResize = useCallback((event?: WindowEventMap["resize"]) => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  const handleResizeDebounce = useCallback(debounce(handleResize, 500), [
    handleResize,
  ]);

  useLayoutEffect(() => {
    handleResizeDebounce();
    window.addEventListener("resize", handleResizeDebounce);

    return () => {
      window.removeEventListener("resize", handleResizeDebounce);
    };
  }, [handleResizeDebounce]);

  return null;
};

export default AdjustHeightOnMobile;
