import { addFailedRequest } from '@/features/App/slices/app.slice';
import { isErrorResponse } from '@/types/common/baseReqRes';
import { Middleware } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';

export const retryMiddleware: Middleware = (store) => (next) => (action, ...rest) => {
  try {
    if (isRejectedWithValue(action)) {
      const payload = action.payload;
  
      const { endpointName, originalArgs, type, fixedCacheKey } = action.meta.arg;
      const sliceName = action.type.replace(/^([^\/]+)(\/?.*)/, '$1')
  
      if (isErrorResponse(payload) && payload.status > 500) {
        if (type === 'mutation' && !fixedCacheKey && !action.meta.requestId) {
          return next(action)
        }

        store.dispatch(addFailedRequest({ endpointName, args: originalArgs, method: type, fixedCacheKey, sliceName, requestId: action.meta.requestId }));
      }
    }

    return next(action);
  } catch (error) {
    console.error("retryMiddleware", error)
    return next(action);
  }
};