import { activityApiSlice } from "@/features/Activities/slices/activityApi.slice";
import { authApiSlice } from "@/features/Auth/slices/authApi.slice";
import { categoryApiSlice } from "@/features/Catalog/slices/categoryApi.slice";
import { pricingRuleApiSlice } from "@/features/Catalog/slices/pricingRuleApi.slice";
import { productVariantApiSlice } from "@/features/Catalog/slices/productVariantApi.slice";
import { stockApiSlice } from "@/features/Catalog/slices/stockApi.slice";
import { configApiSlice } from "@/features/Configuration/slices/configurationApi.slice";
import { dashboardApiSlice } from "@/features/Dashboard/slices/dashboardApi.slice";
import { deliveryOrderApiSlice } from "@/features/DeliveryOrders/slices/deliveryOrderApi.slice";
import { invoiceApiSlice } from "@/features/Invoices/slices/invoiceApi.slice";
import { customerApiSlice } from "@/features/Marketing/slices/customerApi.slice";
import { paymentMethodApi } from "@/features/Operation/slices/paymentMethodApi.slice";
import { staffApiSlice } from "@/features/Operation/slices/staffApi.slice";
import { storeApiSlice } from "@/features/Operation/slices/storeApi.slice";
import { quotationApiSlice } from "@/features/Quotations/slices/quotationApi.slice";
import { taxApiSlice } from "@/features/Taxes/slices/taxApi.slice";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IModalState } from "./../types/modal.types";

export const menuStateKey = "v5-erp-menu-collapsed";

interface FailedRequest {
  endpointName: string;
  args: any;
  method: 'query' | 'mutation'; // Indicate if it's a query or a mutation
  fixedCacheKey: string
  sliceName: string;
  requestId: string;
}

interface AppState {
  sidebarsCollapsed: boolean;
  isLogged: boolean;
  modals: IModalState;
  modalPriority: string[];
  failedRequests: FailedRequest[];
  isRetrying: boolean;
}

const initialState: AppState = {
  sidebarsCollapsed: true,
  isLogged: false,
  modals: {},
  modalPriority: [],
  failedRequests: [],
  isRetrying: false,
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },

    /* Modal */
    setModalBackdrop(state, action: PayloadAction<string>) {},

    toggleModal(state, action: PayloadAction<IModalState>) {
      state.modals = {
        ...state.modals,
        ...action.payload,
      };
      state.modals = Object.fromEntries(
        Object.entries(state.modals).filter(([key, value]) => !!value),
      );
      state.modalPriority = Object.entries(state.modals).reduce(
        (arr, [key, value]) => (!!value ? arr.concat(key) : arr),
        [] as string[],
      );
    },

    resetModal(state) {
      state.modals = initialState.modals;
      state.modalPriority = initialState.modalPriority;
    },

    /* Sidebars */
    setSidebarCollapsed(state, action: PayloadAction<boolean>) {
      state.sidebarsCollapsed = action.payload;
    },

    /* Retry Requests */
    addFailedRequest: (state, action: PayloadAction<FailedRequest>) => {
      state.failedRequests.push(action.payload);
    },
    clearFailedRequests: (state) => {
      state.failedRequests = [];
    },
    startRetrying: (state) => {
      state.isRetrying = true;
    },
    finishRetrying: (state) => {
      state.isRetrying = false;
    },
  },
});

export const sidebarsCollapsedSelector = ({
  appState,
}: {
  appState: AppState;
}) => appState.sidebarsCollapsed;
export const isLoggedSelector = ({ appState }: { appState: AppState }) =>
  appState.isLogged;

export const modalSelector = (
  { appState }: { appState: AppState },
  id: string,
) => appState.modals[id];

export const isLatestModalSelector = (
  { appState }: { appState: AppState },
  id: string,
) => appState.modalPriority.at(-1) === id;

export const modalIndexSelector = (
  { appState }: { appState: AppState },
  id: string,
) => appState.modalPriority.findIndex((e) => e === id);

export const {
  resetState,
  setSidebarCollapsed,
  toggleModal,
  resetModal,
  setModalBackdrop,
  addFailedRequest,
  clearFailedRequests,
  startRetrying,
  finishRetrying
} = appStateSlice.actions;

export const apiSliceCollection = {
  authApiSlice,
  dashboardApiSlice,
  storeApiSlice,
  staffApiSlice,
  categoryApiSlice,
  taxApiSlice,
  productVariantApiSlice,
  pricingRuleApiSlice,
  stockApiSlice,
  customerApiSlice,
  quotationApiSlice,
  invoiceApiSlice,
  deliveryOrderApiSlice,
  configApiSlice,
  activityApiSlice,
  paymentMethodApi,
}

export default appStateSlice.reducer;
