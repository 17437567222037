import { BaseModel } from "@/types/common/base.model"
import { RecordData } from "@/types/common/baseReqRes";
import { Type } from 'class-transformer';
import {
  IsString,
  IsOptional,
  IsDateString,
  IsNumber,
  IsBoolean,
  IsArray,
  ValidateNested,
  IsEnum,
  IsEmpty
} from 'class-validator';
import "reflect-metadata";

export interface RecordPricingBook extends RecordData<PricingBook, 'catalog_pricing_rule'> {

}

export class PricingBook  extends BaseModel {
  @IsString()
  name: string = ''

  @IsEmpty()
  default: null = null

  @IsOptional()
  @IsDateString()
  starts_at: Date | string | null = null

  @IsOptional()
  @IsDateString()
  ends_at: Date | string | null = null

  @IsBoolean()
  include_new_lists: boolean = false

  @IsBoolean()
  include_new_stores: boolean = false

  @IsBoolean()
  all_product: boolean = false

  @IsNumber()
  all_product_operation: number | null = null

  @IsNumber()
  all_product_value: number | null = null

  constructor(init?: Partial<PricingBook>) {
    super();
    Object.assign(this, init);
  }
}