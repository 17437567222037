import { checkMaxPosDigits } from "./../../../utils/commonUtils";
import { DATE_FORMAT } from "@/components/formFields/DateTimeField/DateTimeField.constants";
import { ProductTaxRule } from "@/features/Catalog/types/productTaxRule.model";
import {
  LineItemFieldEnum,
  LineItemValues,
  TDiscountType,
} from "@/features/Catalog/types/stock.model";
import appYup from "@/lib/yup";
import { EMAIL_REGEX } from "@/public/constants/common-values";
import { RecordData, SinglePayload } from "@/types/common/baseReqRes";
import { decimalAdjust } from "@/utils/commonUtils";
import { compareAsc, isMatch, parse } from "date-fns";
import { LineQuotation } from "./lineQuotation.model";
import { Quotation } from "./quotation.model";
import { PricingRule } from "@/features/Catalog/types/pricing_books/pricing_rules.model";
import { decimalAdjustWithBigN, priceBigNumber, qtyBigNumber } from "@/utils/bigNumber";

export const QuotationCreateFields = {
  id: "id",
  status: "status",
  storeId: "store_id",
  customer: "customer",
  quotationNo: "quotation_no",
  quotationNoPlaceholder: "quotation_no_placeholder",
  quotationReference: "quotation_reference",
  quotationDate: "quotation_date",
  dueDate: "due_date",
  quotationItemList: "quotation_item_list",
  notes: "notes",
  shippingFee: "shipping_fee",
  sendEmailInfo: "send_email_info",
  subTotal: "sub_total",
  grandTotal: "grand_total",
  taxType: "tax_type",
  taxTotal: "tax_total",
  adjustmentsTotal: "adjustments_total",
  itemTotal: "item_total",
  oldStructure: "old_structure",
  externalAttributes: "external_attributes",
  deletedLineIds: "deleted_line_ids",
  salesTaxType: "sales_tax_type",
} as const;

export const SendEmailFields = {
  isReady: "is_ready",
  customerEmail: "customer_email",
  contactEmail: "contact_email",
  emailContent: "email_content",
} as const;

export const MakePaymentFields = {
  //Quotation Detail
  customerName: "customer_name",
  invoiceAmount: "invoice_amount",
  outstandingAmount: "outstanding_amount",

  //Payment Detail
  amount: "amount",
  paymentOptionId: "payment_option_id",
  paymentDate: "payment_date",
  reference: "reference",
} as const;

export const QuotationItemListFields = {
  id: "id",
  notes: "notes",
  unitPrice: "unit_price",
  quantity: "quantity",
  taxType: "tax_type",
  taxRuleList: "tax_rule_list",
  discountRuleList: "discount_rule_list",
  taxRateTotal: "tax_rate_total",

  [LineItemFieldEnum.enable_pricing_rule]: LineItemFieldEnum.enable_pricing_rule,
  [LineItemFieldEnum.price_rule_info]: LineItemFieldEnum.price_rule_info,
  [LineItemFieldEnum.price_rule_extra_price]: LineItemFieldEnum.price_rule_extra_price,
} as const;

export const TaxRuleListFields = {
  id: "id",
  itemId: "item_id",
  taxInfo: "tax_info",
  rowAmount: "row_amount",
  taxAmount: "tax_amount",
  peerTaxAmount: "peer_tax_amount",
} as const;

export const DiscountRuleListFields = {
  id: "id",
  discountType: "discount_type",
  discountAmount: "discount_amount",
  rowDiscountTotal: "row_discount_total",
} as const;

export interface QuotationDto extends Quotation {
  [QuotationCreateFields.quotationItemList]: (LineItemValues & LineQuotation)[];
  [QuotationCreateFields.quotationNoPlaceholder]: string;
  [QuotationCreateFields.deletedLineIds]: string[];
}

export interface SendEmailInfoDto {
  [SendEmailFields.isReady]: boolean;
  [SendEmailFields.customerEmail]: string;
  [SendEmailFields.contactEmail]: string;
  [SendEmailFields.emailContent]: string;
}

export interface MakePaymentDto {
  [MakePaymentFields.customerName]: string;
  [MakePaymentFields.invoiceAmount]: string;
  [MakePaymentFields.outstandingAmount]: string;

  [MakePaymentFields.amount]: string;
  [MakePaymentFields.paymentOptionId]: string;
  [MakePaymentFields.paymentDate]: string;
  [MakePaymentFields.reference]: string;
}

export const defaultMakePaymentFields: MakePaymentDto = {
  [MakePaymentFields.customerName]: "",
  [MakePaymentFields.invoiceAmount]: "0",
  [MakePaymentFields.outstandingAmount]: "0",

  [MakePaymentFields.amount]: "0",
  [MakePaymentFields.paymentOptionId]: "",
  [MakePaymentFields.paymentDate]: "",
  [MakePaymentFields.reference]: "",
};

export const defaultSendEmailInfoFields: SendEmailInfoDto = {
  [SendEmailFields.isReady]: false,
  [SendEmailFields.customerEmail]: "",
  [SendEmailFields.contactEmail]: "",
  [SendEmailFields.emailContent]: "",
};

export interface CreateQuotationRecord
  extends RecordData<
    Omit<QuotationDto, "invoice_item_list">,
    "invoice_invoices"
  > {}

export interface SerializedLineQuotation
  extends RecordData<LineQuotation, "invoice_line_invoices"> {}

export interface SerializedQuotation
  extends SinglePayload<
    CreateQuotationRecord,
    SerializedLineQuotation,
    {
      quotation_id: null | string;
    }
  > {}

export const defaultQuotationCreateFields: QuotationDto = {
  [QuotationCreateFields.id]: "",
  [QuotationCreateFields.quotationNoPlaceholder]: "",
  [QuotationCreateFields.status]: "pending",
  [QuotationCreateFields.storeId]: "",
  [QuotationCreateFields.customer]: null,
  [QuotationCreateFields.taxType]: "product_tax",
  [QuotationCreateFields.salesTaxType]: "product_tax",
  [QuotationCreateFields.quotationNo]: "",
  [QuotationCreateFields.quotationReference]: "",
  [QuotationCreateFields.quotationDate]: null,
  [QuotationCreateFields.dueDate]: null,
  [QuotationCreateFields.quotationItemList]: [],
  [QuotationCreateFields.notes]: "",
  [QuotationCreateFields.shippingFee]: "",
  [QuotationCreateFields.subTotal]: "",
  [QuotationCreateFields.grandTotal]: "",
  [QuotationCreateFields.taxTotal]: "",
  [QuotationCreateFields.adjustmentsTotal]: "",
  [QuotationCreateFields.itemTotal]: "",
  [QuotationCreateFields.deletedLineIds]: [],
};

export interface SendEmailInfo {
  [SendEmailFields.isReady]: boolean;
  [SendEmailFields.contactEmail]: string;
  [SendEmailFields.customerEmail]: string;
  [SendEmailFields.emailContent]: string;
}

export interface LineItemFormField extends LineItemValues {
  [QuotationItemListFields.taxRuleList]: TaxRuleFormField[];
  [QuotationItemListFields.discountRuleList]: DiscountRuleFormField[];
}

export interface TaxRuleFormField {
  [TaxRuleListFields.id]?: string;
  [TaxRuleListFields.itemId]?: string;
  [TaxRuleListFields.taxInfo]: ProductTaxRule | Partial<ProductTaxRule> | null;
  [TaxRuleListFields.rowAmount]: number | string;
  [TaxRuleListFields.taxAmount]: number | string;
  [TaxRuleListFields.peerTaxAmount]: number | string;
}

export interface DiscountRuleFormField {
  [DiscountRuleListFields.id]?: string;
  [DiscountRuleListFields.discountType]: TDiscountType;
  [DiscountRuleListFields.discountAmount]: number | string;
  [DiscountRuleListFields.rowDiscountTotal]: number | string;
}

const REQUIRED_MSG = " is required";
const INVALID_EMAIL_MSG = "is invalid";
const DATE_FORMAT_ERROR_MSG = "is not match with format dd/MM/yyyy";
const DATE_CHAR_LENGTH_MSG = " must be at most 20 characters";
const MIN_LIST_MSG = "Need at least 1 ";
const DUE_DATE_AFTER_MSG = "Due date must be on or after invoice date";
const MIN_NUMBER_MSG = "must be greater than or equal to 0";
const DISC_AMOUNT_LARGER_MSG = "Larger than Amount";

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export const MakePaymentFormSchema = appYup.object().shape({
  [MakePaymentFields.customerName]: appYup.string().optional(),
  [MakePaymentFields.invoiceAmount]: appYup.string().optional(),
  [MakePaymentFields.outstandingAmount]: appYup.string().optional(),

  [MakePaymentFields.amount]: appYup
    .string()
    .label("Payment Amount")
    .required()
    .when(
      MakePaymentFields.outstandingAmount,
      (fieldValues: string[], field, amountField) => {
        const outstandingAmount = +fieldValues?.[0];
        if (amountField.value > outstandingAmount) {
          return field.test("Larger than", (val, ctx) =>
            ctx.createError({
              message: "${label} is larger than Outstanding Amount",
            }),
          );
        }

        return field;
      },
    ),
  [MakePaymentFields.paymentOptionId]: appYup
    .string()
    .label("Payment Method")
    .required(),
  [MakePaymentFields.paymentDate]: appYup
    .string()
    .label("Payment Date")
    .required()
    .max(20, `\${label} ${DATE_CHAR_LENGTH_MSG}`)
    .test("Date format", (value, ctx) => {
      if (!value) return false;
      return (
        isMatch(value, DATE_FORMAT) ||
        ctx.createError({ message: `\${label} ${DATE_FORMAT_ERROR_MSG}` })
      );
    }),
  [MakePaymentFields.reference]: appYup.string().optional(),
});

export const SendEmailFormSchema = appYup.object().shape({
  [SendEmailFields.contactEmail]: appYup
    .string()
    .label("Contact Email")
    .required()
    .matches(EMAIL_REGEX, `\${label} ${INVALID_EMAIL_MSG}`),
  [SendEmailFields.customerEmail]: appYup
    .string()
    .label("Customer Email")
    .required()
    .matches(EMAIL_REGEX, `\${label} ${INVALID_EMAIL_MSG}`),
  [SendEmailFields.emailContent]: appYup.string().optional().max(250),
});

export const QuotationCreateFormSchema = appYup.object().shape({
  [QuotationCreateFields.id]: appYup.string().optional(),
  [QuotationCreateFields.storeId]: appYup.string().label("Store").required(),
  [QuotationCreateFields.customer]: appYup
    .object()
    .label("Customer")
    .shape({
      id: appYup.string().label("Customer").required(),
    })
    .transform((val) => (val === "" ? null : val))
    .required(),
  [QuotationCreateFields.quotationNo]: appYup
    .string()
    .label("Quotation No")
    .when(
      QuotationCreateFields.quotationNoPlaceholder,
      (fieldValues: string[], field, invoiceNo) => {
        const invoiceNoPlaceholder = fieldValues?.[0];

        if (!invoiceNo.value && !invoiceNoPlaceholder) {
          return field.required();
        }

        return field;
      },
    ),
  [QuotationCreateFields.quotationReference]: appYup
    .string()
    .label("Quotation Ref")
    .optional()
    .max(50),
  [QuotationCreateFields.quotationDate]: appYup
    .string()
    .label("Quotation Date")
    .required()
    .max(20, `\${label} ${DATE_CHAR_LENGTH_MSG}`)
    .test("Date format", (value, ctx) => {
      if (!value) return false;
      return (
        isMatch(value, DATE_FORMAT) ||
        ctx.createError({ message: `\${label} ${DATE_FORMAT_ERROR_MSG}` })
      );
    }),
  [QuotationCreateFields.dueDate]: appYup
    .string()
    .label("Due Date")
    .required()
    .max(20, `\${label} ${DATE_CHAR_LENGTH_MSG}`)
    .test("Date format", (value, ctx) => {
      if (!value) return false;
      return (
        isMatch(value, DATE_FORMAT) ||
        ctx.createError({ message: `\${label} ${DATE_FORMAT_ERROR_MSG}` })
      );
    })
    .when(
      QuotationCreateFields.quotationDate,
      (fieldValues: string[], field, dueDateField) => {
        const invoiceDate = fieldValues[0]
          ? parse(fieldValues[0], DATE_FORMAT, new Date())
          : null;
        const dueDate = dueDateField?.value
          ? parse(dueDateField.value, DATE_FORMAT, new Date())
          : null;
        if (dueDate && invoiceDate) {
          const dueDateIsAfter = compareAsc(dueDate, invoiceDate);

          if (dueDateIsAfter < 0) {
            return field.test("Validate Date Is After", (val, ctx) =>
              ctx.createError({ message: DUE_DATE_AFTER_MSG }),
            );
          }
        }

        return field;
      },
    ),
  [QuotationCreateFields.grandTotal]: appYup
    .string()
    .test(
      "validate grand total amount",
      "Grand Total maximum is 12 digits",
      (value, ctx) => {
        return checkMaxPosDigits(value, 12).passed;
      },
    ),

  //Quotation Item List
  [QuotationCreateFields.quotationItemList]: appYup.lazy(() =>
    appYup.array().of(
      appYup.object().shape({
        [QuotationItemListFields.id]: appYup.string().required(),
        [QuotationItemListFields.unitPrice]: appYup
          .number()
          .transform((val) => {
            return Number.isNaN(val) ? 0 : +val;
          })
          .min(0, MIN_NUMBER_MSG)
          .required(REQUIRED_MSG),
        [QuotationItemListFields.quantity]: appYup
          .number()
          .transform((val) => +val)
          .moreThan(0, "must be greater than 0")
          .required(REQUIRED_MSG)
          .when(
            QuotationCreateFields.storeId,
            (fieldValues, field, invoiceItemList) => {
              return field;
            },
          ),

        //Pricing Rule
        [QuotationItemListFields[LineItemFieldEnum.price_rule_info]]: appYup.lazy(() =>
          appYup.object().nullable().test(
            "isPositivePriceCheck", 
            "Final price must be positive!",
            (_pricing_rule, ctx) => {
              const [pricingRule, { unit_price, price_rule_extra_price }] = (ctx.from || []).map(
                (obj) => obj.value,
              ) as unknown as [any, LineItemValues];

              if (!pricingRule) return true;

              let finalPrice = decimalAdjustWithBigN(
                priceBigNumber(unit_price || 0).plus(priceBigNumber(price_rule_extra_price || 0)),
              );

              return finalPrice.isGreaterThanOrEqualTo(0)
            }
          ),
        ),

        //Tax Row List
        [QuotationItemListFields.taxRuleList]: appYup.lazy(() =>
          appYup.array().of(
            appYup.object().shape<PartialRecord<keyof TaxRuleFormField, any>>({
              tax_info: appYup
                .object()
                .shape({
                  id: appYup.string().required("Tax Rule" + REQUIRED_MSG),
                })
                .transform((val) => {
                  return val === "" ? null : val;
                })
                .required("Tax Rule" + REQUIRED_MSG),
            }),
          ),
        ),
        [QuotationItemListFields.discountRuleList]: appYup.lazy(() =>
          appYup.array().of(
            appYup
              .object()
              .shape<PartialRecord<keyof DiscountRuleFormField, any>>({
                row_discount_total: appYup
                  .number()
                  .transform((val) => {
                    return Number.isNaN(val) ? 0 : +val;
                  })
                  .required(REQUIRED_MSG)
                  .test(
                    "validate discount amount",
                    DISC_AMOUNT_LARGER_MSG,
                    (value, ctx) => {
                      const [discountInfo, { unit_price, quantity, price_rule_extra_price }] = (ctx.from || []).map(
                        (obj) => obj.value,
                      ) as unknown as [DiscountRuleFormField, LineItemValues];
                      
                      let amount = decimalAdjustWithBigN(
                        priceBigNumber(unit_price || 0).plus(priceBigNumber(price_rule_extra_price || 0)).multipliedBy(qtyBigNumber(quantity || 0)),
                      );

                      if (discountInfo.discount_type === "percentage") {
                        return +discountInfo.discount_amount <= 100;
                      }

                      return amount.absoluteValue().isGreaterThanOrEqualTo(value)
                    },
                  ),
              }),
          ),
        ),
      }),
    ),
  ),
});

export const invoiceSearchSchema = appYup.object().shape({
  search_field: appYup.string(),
  invoice_start_date: appYup
    .date()
    .nullable()
    .test(
      "start-before-end",
      "Quotation Start date must be before End date",
      function (value) {
        const { invoice_end_date } = this.parent;
        if (!value || !invoice_end_date) {
          return true;
        }
        return value < invoice_end_date;
      },
    ),
  invoice_end_date: appYup
    .date()
    .nullable()
    .test(
      "end-after-start",
      "Quotation End date must be after Start date",
      function (value) {
        const { invoice_start_date } = this.parent;
        if (!value || !invoice_start_date) {
          return true;
        }
        return invoice_start_date < value;
      },
    ),
  due_start_date: appYup
    .date()
    .nullable()
    .test(
      "start-before-end",
      "Due Start date must be before End date",
      function (value) {
        const { due_end_date } = this.parent;
        if (!value || !due_end_date) {
          return true;
        }
        return value < due_end_date;
      },
    ),
  due_end_date: appYup
    .date()
    .nullable()
    .test(
      "end-after-start",
      "Due End date must be after Start date",
      function (value) {
        const { due_end_date } = this.parent;
        if (!value || !due_end_date) {
          return true;
        }
        return due_end_date < value;
      },
    ),
});
