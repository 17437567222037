import {
  IsDateString,
  IsOptional,
  IsString
} from 'class-validator';

export class BaseModel {
  @IsString()
  id: string = ''

  @IsOptional()
  @IsDateString()
  deleted_at: Date | string | null = null

  @IsOptional()
  @IsDateString()
  created_at: Date | string | null = null

  @IsOptional()
  @IsDateString()
  updated_at: Date | string | null = null

  constructor(init?: Partial<BaseModel>) {
    Object.assign(this, init);
  }
}