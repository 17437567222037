import type { TSalesTaxConfigType } from "@/features/Configuration/types/configuration.model";
import {
  DiscountRuleFormField,
  TaxRuleFormField,
} from "@/features/Invoices/types/invoice.create.schema";
import type { TTaxType } from "@/features/Operation/types/tax.model";
import { BaseModel } from "@/types/common/base.model";
import { RecordData } from "@/types/common/baseReqRes";
import { decimalAdjustWithBigNStr, priceBigNumber } from "@/utils/bigNumber";
import { Expose, Transform, Type } from "class-transformer";
import { IsBoolean, IsNumber, IsOptional, IsString, ValidateNested } from "class-validator";
import { PricingRule } from "./pricing_books/pricing_rules.model";
import type { ProductVariant, RecordCompositeProduct } from "./product.model";
import { ProductTaxGroup } from "./productTaxGroup.model";
import type { ProductTaxRule } from "./productTaxRule.model";

export type TLineItemOrigin = "select" | "import";

export type TDiscountType = "percentage" | "amount" | null;

export interface RecordStock
  extends RecordData<ErpSalesProductVariant, string> {}
export interface ErpSalesProductVariant extends ProductVariant {
  id: string;
  default: boolean;
  name: string;
  name_override: string | null;
  description_override: string | null;
  base_price: string | number;
  sku: string;
  track_inventory: boolean;
  manual_discount_disabled: boolean;
  available: boolean;
  external_attributes: {};
  barcode: "-";
  product: ProductVariant;
  deleted_at: Date | string | null;
  cost_price: string | number;
  product_composites: RecordCompositeProduct[];
  tax_group: ProductTaxGroup;
  tax_rules: ProductTaxRule[];
  pricing_rules: PricingRule[] | null;
}

export interface StockOfVariantListParams {
  sku_list: string[];
  store_id?: string;
  customer_id?: string;
  start_date?: string;
  end_date?: string
}

export interface PricingRuleProperties extends Pick<LineItemValues, 'price_rule_info' | 'price_rule_extra_price' | 'enable_pricing_rule' | 'pricing_rules' > {

}

export class LineItemValues extends BaseModel {
  @IsString()
  sku: string = "";

  @IsOptional()
  @IsString()
  invoice_id: string = "";

  @IsString()
  _line_id: string = "";

  @IsString()
  product_variant_id: string = "";

  @IsOptional()
  @ValidateNested()
  product_variant?: ErpSalesProductVariant;

  @IsString()
  @IsNumber()
  quantity: string | number = "0.00";

  @IsString()
  @IsNumber()
  unit_price: string | number = "0.00";

  @IsString()
  @IsNumber()
  amount: string | number = "0";

  @IsString()
  @IsNumber()
  sub_total: string | number = "0";

  @IsString()
  @IsNumber()
  sub_total_for_display: string | number = "0";

  @IsString()
  @IsNumber()
  net_price: string | number = "0";

  @IsOptional()
  @IsString()
  properties?: string;

  @IsString()
  notes: string = "";

  @IsOptional()
  mapping?: Record<string, any>;

  @ValidateNested()
  tax_group: ProductTaxGroup | null = null;

  @ValidateNested({ each: true })
  tax_rules: ProductTaxRule[] = [];

  @IsString()
  tax_type: TTaxType = "tax_exclusive";

  @IsBoolean()
  no_tax: boolean = true;

  @IsString()
  sales_tax_type: TSalesTaxConfigType = "product_tax";

  @ValidateNested({ each: true })
  discount_rule_list: DiscountRuleFormField[] = [];

  @ValidateNested({ each: true })
  tax_rule_list: TaxRuleFormField[] = [];

  @IsOptional()
  @IsBoolean()
  new_record?: boolean;

  @IsString()
  origin: TLineItemOrigin = "select";

  @IsOptional()
  @IsBoolean()
  _destroy?: boolean;

  @IsString()
  @IsNumber()
  net_amount: string | number = "";

  @IsString()
  @IsNumber()
  discount_amount: string | number = 0;

  @IsString()
  @IsNumber()
  tax_rate_total: string | number = "";


  //Pricing Rule Display Info
  @IsBoolean()
  enable_pricing_rule: boolean = false;

  //Pricing Rule Submit Info
  private _price_rule_info: PricingRule | null | Record<string, any> = null;

  @Expose()
  @IsOptional()
  @ValidateNested({ each: true })
  @Transform(({ value }: { value: PricingRule}) => {
    if (value && value.override_value) {
      value.override_value = decimalAdjustWithBigNStr(priceBigNumber(value.override_value))
    }
    return value
  })
  @Type(() => PricingRule)
  get price_rule_info(): PricingRule | null | Record<string, any> {
    return this._price_rule_info;
  }

  set price_rule_info(value: PricingRule | null | Record<string, any>) {
    this._price_rule_info = value ? new PricingRule(value) : null;
    if (value && value?.id) {
      this.enable_pricing_rule = true
    }
    if (this.price_rule_info) {

      if (!this.price_rule_info.override_value) {
        this.price_rule_info?.calculateOverrideValue(this.product_variant?.base_price)
      }

      if (!this.price_rule_extra_price) {
        this.price_rule_extra_price = this.price_rule_info.calculateExtraPrice(this.product_variant?.base_price);
      }
    }
  }

  private _pricing_rules: PricingRule[] = [];

  @Expose()
  @Transform(({ value }) => value ? null : (value as PricingRule[]).map(item => new PricingRule(item)))
  @ValidateNested({ each: true })
  @Type(() => Array<PricingRule>)
  get pricing_rules(): PricingRule[] {
    return this._pricing_rules;
  }

  set pricing_rules(value: PricingRule[] | null | undefined) {
    this._pricing_rules = value ?? [];
    if(this._pricing_rules.length > 0 && (this._price_rule_info?.id === 'base_price' || !this._price_rule_info)) {
      this.price_rule_info = this.pricing_rules.find(item => item.auto_apply) ?? this.pricing_rules[0]
      this.price_rule_info.calculateOverrideValue(this.product_variant?.base_price)
    }
  }

  @IsOptional()
  @IsString()
  @IsNumber()
  price_rule_extra_price: string | number = 0;

  // Constructor to accept default and custom values
  constructor(init?: Partial<LineItemValues>) {
    super()
    Object.assign(this, init);
  }

  setValues(values?: Partial<LineItemValues>) {
    if (!values) return;

    let newLine = values
    
    if (newLine.price_rule_info?.id) {
      newLine.price_rule_info = new PricingRule(newLine.price_rule_info)
    } else {
      newLine.price_rule_info = null
    }

    Object.assign(this, newLine);
  }
}

export type LineItemValuesPlain = Omit<LineItemValues, '_price_rule_info' | 'price_rule_info' | 'getters' | 'setters' | 'any_methods'> & {
  price_rule_info: PricingRule | null;
};


export enum LineItemFieldEnum {
  id = "id",
  sku = "sku",
  _line_id = "_line_id",
  invoice_id = "invoice_id",
  product_variant_id = "product_variant_id",
  product_variant = "product_variant",
  amount = "amount",
  sub_total = "sub_total",
  net_price = "net_price",
  sub_total_for_display = "sub_total_for_display",
  quantity = "quantity",
  unit_price_origin = "unit_price_origin",
  unit_price = "unit_price",
  notes = "notes",
  tax_rules = "tax_rules",
  tax_group = "tax_group",
  discount_rule_list = "discount_rule_list",
  tax_rule_list = "tax_rule_list",
  net_amount = "net_amount",
  discount_amount = "discount_amount",
  tax_rate_total = "tax_rate_total",
  origin = "origin",
  tax_type = "tax_type",
  sales_tax_type = "sales_tax_type",
  no_tax = "no_tax",

  //pricing rule
  enable_pricing_rule = "enable_pricing_rule",
  price_rule_info = "price_rule_info",
  price_rule_extra_price = "price_rule_extra_price",
  pricing_rules = "pricing_rules",
}

export const defaultLineItemValues: LineItemValues = new LineItemValues;
