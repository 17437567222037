import EposArrowDownIcon from "@/public/icons/common/ArrowDown.icon";
import { InputRadius } from "@/public/styles/spacingConstants";
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  CSSInterpolation,
  Theme,
} from "@mui/material/styles";
import { createElement } from "react";

const IconComponent = (props: any) => {
  return createElement(EposArrowDownIcon, { ...props });
};

export const SelectVariant: {
  defaultProps?: ComponentsProps["MuiSelect"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiSelect"];
  variants?: ComponentsVariants["MuiSelect"];
} = {
  defaultProps: {
    size: "medium",
    variant: "outlined",
    IconComponent: IconComponent,
    MenuProps: {
      autoFocus: false,
      PaperProps: {
        eevation: 0,
        sx: {
          boxShadow: "var(--shadow-secondary-center)",
          borderRadius: "8px",
          marginTop: "0.25rem",
        },
      },
    },
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: InputRadius,
      margin: theme.spacing(1, 0),

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&:hover:not(.Mui-disabled)": {
        boxShadow: "var(--shadow-secondary-center-for-input)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: theme.palette.primary[30],
        },
      },
      "&.Mui-focused": {
        boxShadow: "var(--shadow-secondary-center-for-input)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: theme.palette.primary[10],
        },
      },
    }),
    select: ({ theme, ownerState: { size } }) => {
      let tableCellSize: CSSInterpolation = {};
      let inputStyle: CSSInterpolation = {
        height: "auto",
        lineHeight: 1.5,
        padding: theme.spacing(1.5, 0.5, 1.5, 1),
        overscrollBehavior: "contain",
      };

      if (size === "table-cell") {
        tableCellSize = {
          minHeight: 'auto',
          fontSize: 14,
          lineHeight: "18px",
          padding: theme.spacing(0.875, 2, 0.875, 2),
        };
        return {
          "&.MuiInputBase-input": {
            ...inputStyle,
            ...tableCellSize,
          },
        };
      }

      return {
        "&.MuiInputBase-input": {
          ...inputStyle
        },
      }
    },
    icon: ({ theme, ownerState: { size } }) => {

      if (size === "table-cell") {
        return {
          fontSize: '1.25rem'
        };
      }

      return {
        fontSize: '1.5rem'
      }
    }
  },
};
