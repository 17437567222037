import { DisabledColors } from "@/public/styles/colorConstants";
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles";
import { InputRadius } from "@/public/styles/spacingConstants";
import { CSSObject } from "@mui/material";

export const TextFieldVariant: {
  defaultProps?: ComponentsProps["MuiTextField"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiTextField"];
  variants?: ComponentsVariants["MuiTextField"];
} = {
  defaultProps: {
    variant: "outlined",
  },
  styleOverrides: {
    root: ({ theme, size }) => {
      let customStyles: CSSObject = {};
      if (size === "table-cell") {
        customStyles = {
          margin: 0,

          "& .MuiInputBase-root.MuiInputBase-multiline": {
            padding: theme.spacing(0.25),
            paddingLeft: theme.spacing(0.5),
          },
        };
      } else {
        customStyles = {
          "& .MuiInputBase-root.MuiInputBase-multiline": {
            padding: theme.spacing(0.75),
            paddingLeft: theme.spacing(1),
          },
        };
      }

      return {
        "& .MuiInputBase-root": {
          borderRadius: InputRadius,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "&:hover:not(.Mui-disabled)": {
            boxShadow: "var(--shadow-secondary-center-for-input)",
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
              borderColor: theme.palette.primary[30],
            },
          },
          "&.Mui-focused": {
            boxShadow: "var(--shadow-secondary-center-for-input)",
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
              borderColor: theme.palette.primary[10],
            },
          },
        },
        "& .MuiInputLabel-root": {
          backgroundColor: theme.palette.background.light,
          width: "fit-content",
          transform: "translate(14px, 13px) scale(1)",
          "&.Mui-focused,&.MuiFormLabel-filled": {
            paddingLeft: 2,
            paddingRight: 4,
            fontSize: 18,
            fontWeight: 700,
            transform: "translate(.75rem, -0.5625rem) scale(0.8)",
          },
        },
        "& *.Mui-disabled": {
          cursor: "text",
          color: `${DisabledColors.Disabled05} !important`,
          WebkitTextFillColor: `${DisabledColors.Disabled05} !important`,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent !important",
          },
        },
        ...customStyles,
      };
    },
  },
};
