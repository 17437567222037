import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles";
import { ButtonRadius } from "@/public/styles/spacingConstants";
import { alpha } from "@mui/material";
import { baseInputColor, DisabledColors } from "@/public/styles/colorConstants";

/**
 * medium => large, small => medium, xSmall => small
 */

const iconSize = {
  xSmall: "1rem",
  small: "1.125rem",
  medium: "1.5rem",
  large: "1.75rem",
};

export const ButtonVariant: {
  defaultProps?: ComponentsProps["MuiButton"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiButton"];
  variants?: ComponentsVariants<Theme>["MuiButton"];
} = {
  defaultProps: {
    size: "medium",
    variant: "contained",
    color: "buttonPrimary",
  },
  styleOverrides: {
    root: ({ theme, ownerState: { variant, size, color, fullWidth } }) => ({
      borderRadius: ButtonRadius,

      textTransform: "none",
      whiteSpace: "nowrap",
      boxShadow: "none",
      fontWeight: 500,

      color: variant === "contained" ? "#fff" : undefined,

      "&:hover": {
        boxShadow: "none",
      },
      "&.Mui-disabled": {
        color: DisabledColors.Disabled20,

        "&.MuiButton-outlined": {
          backgroundColor: DisabledColors.Disabled10,
        },

        "&.MuiButton-contained": {
          backgroundColor: DisabledColors.Disabled10,
        },

        "& svg path": {
          stroke: DisabledColors.Disabled20,
        },
        "&:not(.MuiButton-text)": {
          backgroundColor: DisabledColors.Disabled10,
        },
      },
    }),
    startIcon: ({ ownerState: { size } }) => ({
      marginLeft: 0,
      marginRight: "0.5rem",
      display: "flex",
      alignItems: "center",

      "&>*:nth-of-type(1)": {
        fontSize: iconSize[size || "medium"],
      },
    }),
    endIcon: ({ ownerState: { size } }) => ({
      marginLeft: "0.5rem",
      marginRight: 0,
      display: "flex",
      alignItems: "center",

      "&>*:nth-of-type(1)": {
        fontSize: iconSize[size || "medium"],
      },
    }),
    sizeMedium: {
      fontSize: "1.125rem",
      lineHeight: 1.375,
      minWidth: "3.125rem",
      height: "2.5rem",
    },
    iconSizeMedium: {
      width: "1.5rem",
      height: "1.5rem",
    },
    sizeLarge: {
      fontSize: "1.25rem",
      lineHeight: 1.5,
      height: "3rem",
    },
    iconSizeLarge: {
      width: "2rem",
      height: "2rem",
    },
    sizeSmall: {
      fontSize: "0.875rem",
      lineHeight: 1.125,
      height: "2rem",
      padding: ".4375rem",
    },
    fullWidth: {
      minWidth: undefined,
    },
    outlined: {
      borderWidth: 1.5,
      borderStyle: "solid",
      "&:hover": {
        borderWidth: 1.5,
        borderStyle: "solid",
      },
    },
    containedSecondary: ({ theme }) => ({
      color: "#ffffff",
      "&:hover": {
        backgroundColor: alpha(theme.palette.secondary.main, 0.8),
      },
    }),
    contained: ({ theme }) => ({
      color: "#ffffff",
    }),
    outlinedInherit: ({ theme }) => ({
      "&:hover": {
        backgroundColor: "#ECEEF1",
      },
    }),
    outlinedSizeLarge: {
      borderWidth: 2,
      "&:hover": {
        borderWidth: 2,
      },
    },
  },
  variants: [
    {
      props: { size: "xSmall" },
      style: ({ theme }) => ({
        fontSize: theme.typography.body6.fontSize,
        padding: theme.spacing(0.25, 0.5),
      }),
    },
    {
      props: { variant: "ghost" },
      style: ({ theme }) => ({
        backgroundColor: baseInputColor.backgroundColor,
        color: theme.palette.error.main,
      }),
    },
    {
      props: { variant: "field" },
      style: ({ theme }) => ({
        backgroundColor: baseInputColor.backgroundColor,
        color: theme.palette.variant.main,
      }),
    },
    {
      props: { variant: "link" },
      style: ({ theme }) => ({
        backgroundColor: "transparent",
        color: theme.palette.primary.light,
        padding: theme.spacing(1, 0),
        "&:hover": {
          textDecoration: "underline",
          backgroundColor: "transparent",
        },
        "&.Mui-disabled": {
          color: DisabledColors.Disabled20,
          backgroundColor: "transparent",

          "&.MuiButton-outlined": {
            backgroundColor: "transparent",
          },

          "&.MuiButton-contained": {
            backgroundColor: "transparent",
          },

          "& svg path": {
            stroke: DisabledColors.Disabled20,
          },
          "&:not(.MuiButton-text)": {
            backgroundColor: "transparent",
          },
        },
      }),
    },
  ],
};
