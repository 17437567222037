"use client";

import { Backdrop, Box, styled } from "@mui/material";

export const DefaultLayoutWrapper = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.palette.background.main};
  background-image: url('/icons/common/logo.icon.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - ${theme.spacing(3)}) calc(100% - ${theme.spacing(2)});
  background-blend-mode: color-burn;
  background-size: 3rem;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * 100);
`,
);
