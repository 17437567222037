import { ReactNode } from "react";
import { DefaultLayoutWrapper } from "./DefaultLayout.styles";
import AdjustHeightOnMobile from "./AdjustHeightOnMobile";

export interface DefaultLayoutProps {
  children: ReactNode;
}

export function DefaultLayout({ children }: DefaultLayoutProps) {
  return (
    <DefaultLayoutWrapper>
      <AdjustHeightOnMobile />
      {children}
    </DefaultLayoutWrapper>
  );
}
