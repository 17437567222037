import { CircularProgress, CircularProgressProps } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { ICustomProgressProps } from "./CustomProgress.props";
import { StyledProgressWrapper } from "./CustomProgress.styles";

export const CustomCircularProgress = ({
  id,
  children,
  gradiant,
  ...props
}: CircularProgressProps & ICustomProgressProps) => {
  const uuid = id ?? nanoid();
  if (!gradiant)
    return (
      <StyledProgressWrapper>
        <CircularProgress {...props} />
        {children}
      </StyledProgressWrapper>
    );

  return (
    <StyledProgressWrapper>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id={uuid} x1="0%" y1="0%" x2="0%" y2="100%">
            {Object.keys(gradiant).map((key: any, index) => (
              <stop
                // eslint-disable-next-line react/no-array-index-key
                key={`${uuid}_${index}`}
                offset={key}
                stopColor={gradiant[key] ?? ""}
              />
            ))}
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress
        sx={{
          "svg circle": {
            stroke: `url(#${uuid})`,
            strokeLinecap: "round",
          },
        }}
        {...props}
      />
      {children}
    </StyledProgressWrapper>
  );
};
