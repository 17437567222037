import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
  Theme,
} from "@mui/material/styles";

export const SvgIcon: {
  defaultProps?: ComponentsProps["MuiSvgIcon"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiSvgIcon"];
  variants?: ComponentsVariants["MuiSvgIcon"];
} = {
  variants: [
    {
      props: { fontSize: "xsmall" },
      style: ({ theme }) => ({
        fontSize: "0.5rem",
      }),
    },
  ],
};
